//React Hooks
import React from "react";

//Material UI
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery40Icon from "@material-ui/icons/Battery30";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery90Icon from "@material-ui/icons/Battery90";

//Helper Functions
import {
  convertDateInFailsDevices,
  changeColorIconBatteryLevel,
} from "../../utils/helpers";

//Images
import { Devices } from "../../constants/devices_types";

//multilanguage
import { useTranslation } from "react-i18next";

const DeviceFails = ({ data }) => {
  const filteredDevices = data?.Device?.filter(
    (item) =>
      item?.Category?.name?.toLowerCase() !== "generic" &&
      item?.Category?.name?.toLowerCase() !== "controlador de escena" &&
      item?.Category?.name?.toLowerCase() !== "generic i/o repeater"
  );
  const { t } = useTranslation();
  // return
  const img_devices_fails = {
    bombilla_regulable: Devices.BINARY_LIGHT_OFF,
    bloqueo_de_puerta: Devices.DOOR_DESBLOQUEADO,
    bombilla_regulable_de_pared: Devices.BINARY_LIGHT_OFF,
    door_lock: Devices.DOOR_DESBLOQUEADO,
    hvac: Devices.THERMOSTAT_DEVICE_DEFAULT,
    interruptor: Devices.BINARY_LIGHT_OFF,
    interruptor_de_pared: Devices.BINARY_LIGHT_OFF,
    medidor_de_energia: Devices.METER_DEFAULT,
    sensor_de_movimiento: Devices.MOTION_SENSOR,
    sensor_de_puerta: Devices.DOOR_WINDOW,
    security_sensor: Devices.DOOR_WINDOW,
    sirena: Devices.SIREN_OFF,
    switch: Devices.BINARY_LIGHT_OFF,
    termostato: Devices.THERMOSTAT_DEVICE_DEFAULT,
    ventana: Devices.CURTAIN,
  };

  const imgTraductor = (i) => {
    const __img__ = i?.Category?.name?.toLowerCase().replace(/\s/g, "_");
    // console.log("__img__:", __img__);
    return img_devices_fails[__img__];
  };

  const batteryIcon = (lv) => {
    if (lv >= 1 && lv <= 20) {
      return <Battery20Icon />;
    } else if (lv > 20 && lv <= 40) {
      return <Battery40Icon />;
    } else if (lv > 40 && lv <= 60) {
      return <Battery60Icon />;
    } else if (lv > 60 && lv <= 80) {
      return <Battery80Icon />;
    } else {
      return <Battery90Icon />;
    }
  };

  const translatingFailedDevices = (device) => {
    if (device === "ventana") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.WINDOWS"
      );
    } else if (device === "sensor de puerta") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.DOOR_SENSOR"
      );
    } else if (device === "interruptor") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.SWITCH"
      );
    } else if (device === "bombilla regulable de pared") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.ADJUSTABLE_WALL_LIGHT"
      );
    } else if (device === "termostato") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.THERMOSTAT"
      );
    } else if (device === "bombilla regulable") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.ADJUSTABLE_LIGHT"
      );
    } else if (device === "sensor de movimiento") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.MOVEMENT_SENSOR"
      );
    } else if (device === "bloqueo de puerta") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.LOCK_DOOR"
      );
    } else if (device === "sensor uv") {
      return t(
        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.UV_SENSOR"
      );
    } else {
      return device;
    }
  };

  return (
    <div className="__DFPC_DF_content__">
      <div className="__place__">{data?.name}</div>
      <div className="__DFPC_DF_container_devices__">
        {filteredDevices?.map((i, key) => (
          <div key={key} className="__info__">
            <img src={`${imgTraductor(i)}`} alt="Img" />
            <div className="__description_content__">
              <div className="__title_category__">
                {translatingFailedDevices(
                  i?.Category?.name?.toLowerCase().replace(/_/g, " ")
                )}
              </div>
              <div className="__title_description__">{i?.alias}</div>
              {i?.variables?.BatteryLevel &&
              i?.variables?.BatteryLevel === "1" ? (
                <div className="__BaterryLevel_on__">
                  <div className="battery_device_level">
                    <strong>
                      {t(
                        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.BATTERY_LEVEL"
                      )}
                    </strong>
                    <span className="battery_device_averange">
                      {i?.variables?.BatteryLevel}%
                    </span>
                    <span
                      className={`battery_device_level_icon ${changeColorIconBatteryLevel(
                        i?.variables?.BatteryLevel
                      )}`}
                    >
                      {batteryIcon(i?.variables?.BatteryLevel)}
                    </span>
                  </div>
                </div>
              ) : i?.variables?.FailureDate ? (
                <div className="__date_offline__">
                  <span>
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.DATE_FAILURE_DESCRIPTION"
                    )}
                  </span>
                  {convertDateInFailsDevices(i?.variables?.FailureDate)}
                </div>
              ) : (
                <div className="__date_offline__">
                  {t(
                    "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.NO_INFO"
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeviceFails;
